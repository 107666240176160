<template>
	<div class="details">
		<Selectshop
		 :proData="transmitData"
		  />

		<div class="conten_body">
			<div class="conten_left">
				<ul class="nav_list">
					<li><a class="a_active" href="">产品介绍</a></li>
					<li><a href="">服务流程</a></li>
					<li><a href="">服务优势</a></li>
					<li><a href="">常见问题</a></li>
				</ul>

         <!-- 在公有云上过等保我们需要哪些安全设备？ -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     在公有云上过等保我们需要哪些安全设备？
				   </h3>
				   <ul class="meal_box">
				   	 <li>
				   	 	<img class="" src="@/assets/images/dengbaomeal/a02.jpg">
				   	 	<span>云防火墙</span>
				   	 </li>
				   	  <li>
				   	 	<img class="" src="@/assets/images/dengbaomeal/a03.jpg">
				   	 	<span>病毒防护</span>
				   	 </li>
				   	  <li>
				   	 	<img class="" src="@/assets/images/dengbaomeal/a04.jpg">
				   	 	<span>日志审计</span>
				   	 </li>
				   	  <li>
				   	 	<img class="" src="@/assets/images/dengbaomeal/a05.jpg">
				   	 	<span>堡垒机</span>
				   	 </li>
				   </ul>
				</div>

				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     万方公有云二级等保整改套餐
				   </h3>
				    <p class="deng_item">
				   	<span></span>
                    万方为广大公有云（阿里云、腾讯云、华为云）等云平台客户提供二级等保整改全套安全产品保证客户满足二级等保整改要求，同时也会配套提供安全整改服务，让客户更省心、省事的通过等保测评
				   </p>
				 <table class="table_style" border="1">
					  <tr>
					    <th>产品中心</th>
					    <th>合规对应要求</th>
					  </tr>
					  <tr>
					    <td>云防火墙</td>
					    <td>满足等保要求中的边界防护和访问控制等要求</td>
					  </tr>
					  <tr>
					    <td>云安全中心</td>
					    <td>满足等保要求中安全审计和集中管控的要求</td>
					  </tr>
					  <tr>
					    <td>云日志审计</td>
					    <td>满足等保要求中日志审计和存储时间的要求</td>
					  </tr>
					  <tr>
					    <td>云堡垒机</td>
					    <td>满足等保要求中身份鉴别、访问控制和安全审计等要求</td>
					  </tr>
					</table>
					 <!-- <p>
                    	<a class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>
				<!-- 万方的优势 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     万方的优势
				   </h3>
				    <div class="kai_items">
				    	<img class="kai_icons" src="@/assets/images/b16.png">
				    	<div class="kai_de">
				    		<h4>价格优势</h4>
				    		<p><span></span>阿里云、腾讯云、华为云、一个二级等保按照半年时间来购买需要大概3万以上</p>
				    		<p><span></span>我们万方按照上述产品清单配置，只需要2.1万元，价格优势非常明显</p>

				       </div>
				    </div>
				    <div class="kai_items">

				    	<div class="kai_de">
				    		<h4>服务优势</h4>
				    		<p><span></span>万方是一个安全服务公司，有强大的技术团队，我们专注于安全服务已经12年，有信心为客户提供优质的整改服务，保障客户等保测评的顺利通过</p>

				       </div>
				       <img class="kai_icons" src="@/assets/images/b14.png">
				    </div>
				</div>
				<!-- 万方资质 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     万方资质
				   </h3>
				   <img style="width:100%;" src="@/assets/images/dengbaomeal/a06.jpg">
				</div>
				<!-- 行业案例 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     行业案例
				   </h3>
				   <ul class="case">
				   	 <li>
				   		<span class="tis">1.医疗行业</span>
				   		<img class="case_img" src="@/assets/images/b17.png">
				   		<div class="case_items">
				   			<h6>丽珠医药集团股份有限公司</h6>
				   			<div class="case_items_list">
				   				<span>需求：</span>等保三级
				   			</div>
				   			<div class="case_items_list">
				   				<span>周期：</span>2个月时间完成，由于客户新开业务，整改配套设施耗时较长；
				   			</div>
				   			<div class="case_items_list">
				   				<span>收益：</span>(1)满足合规性需求；<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身医疗系统经过安全测评及整改，系统安全性有基本的了解及提高。
				   			</div>
				   		</div>
				   	 </li>
				   	  <li>
				   		<span class="tis">2.金融行业</span>
				   		<img class="case_img" src="@/assets/images/b18.png">
				   		<div class="case_items">
				   			<h6>东莞市德吉特软件科技有限公司</h6>
				   			<div class="case_items_list">
				   				<span>需求：</span>等保三级
				   			</div>
				   			<div class="case_items_list">
				   				<span>周期：</span>1.5个月完成；
				   			</div>
				   			<div class="case_items_list">
				   				<span>收益：</span>(1)满足金融行业监管需求；<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身金融业务系统经过安全测评及整改，系统安全性有基本的了解， 并从根本上得以提高。
				   			</div>
				   		</div>
				   	 </li>
				   	  <li>
				   		<span class="tis">3.教育行业</span>
				   		<img class="case_img" src="@/assets/images/b19.png">
				   		<div class="case_items">
				   			<h6>深圳市前海美联科技有限公司</h6>
				   			<div class="case_items_list">
				   				<span>需求：</span>等保三级
				   			</div>
				   			<div class="case_items_list">
				   				<span>周期：</span>2个月时间完成;
				   			</div>
				   			<div class="case_items_list">
				   				<span>收益：</span>(1)满足合规性需求<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身在线教育系统经过安全测评及整改，系统安全性有基本的了解及提高。
				   			</div>
				   		</div>
				   	 </li>
				   </ul>
				   <span class="more_case">更多案例请点击</span>
				   <!-- <p style="" class="kai_desc1">等保测评刻不容缓，找万方！</p>
				     <p>
                    	<a style="margin-top:10px;margin-bottom:30px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>

			</div>
			<!-- right -->
			<Hotgoods/>


		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
import Selectshop from "@/components/Selectshop.vue"

export default{
	components:{
      Hotgoods,
      Selectshop
	},
data(){
	return{
		transmitData:{
			title:"阿里云二级等保整改安全产品套餐(包过)",
			content:"",
			price:'21000.00',
			url:require('../../assets/images/dengbaomeal/a01.jpg')
		}
	}
},
mounted(){

},
methods:{


}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;
//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px;
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	padding:0 35px;
	overflow:hidden;
}
.con_list img{
	// width:100%;
}
.con_list h3{
	font-size: 22px;
	font-weight: bold;
	color: #2B2B2B;
	line-height: 42px;
	margin-top:40px;
}
.icons{
	width:11px;
	height:28px;
	position:relative;
	top:-2px;
}
.meal_box{
	width:800px;
	margin:10px auto;
	display: flex;
	justify-content: space-between;
}
.meal_box>li>img{
	width:85px;
	height:85px;
	padding-top:7px;
}
.meal_box>li{
	width:120px;
	height:120px;
	padding:10px;
	text-align:center;
	border:2px solid #007CC6;
	border-radius: 50%;
}
.meal_box>li>span{
	display: block;
	text-align:center;
	font-size:16px;
}
.deng_item {
	position:relative;
	font-size: 16px;
	font-weight: 300;
	color: #2B2B2B;
	line-height: 23px;
	margin-left:20px;
	margin-top:15px;
}
.deng_item span{
	width: 6px;
	height:6px;
	border: 2px solid #007CC6;
	border-radius: 50%;
	position:absolute;
	top:5px;
	left:-18px;
}
.table_style{
	border-collapse: collapse;
	text-align:center;
	font-size:16px;
	line-height: 40px;
	margin:20px auto;
	border-color:#0e82cb;
}
.table_style th{
	background:#007CC6;
	color:#fff;
}
.table_style th:nth-of-type(1){
	width:200px;
}
.table_style th:nth-of-type(2){
	width:500px;
}
.table_style td:nth-of-type(2){
  padding-left:20px;
  text-align:left;
}
.kai_phone_box{
	display: inline-block;
	margin:0 auto;
	position:relative;
	margin-top:35px;
	left:50%;
	width:250px;
	margin-left:-125px;
}
.kai_items{
	margin-top:12px;
    overflow:hidden;
	font-size: 17px;
	font-weight: 300;
	color: #2B2B2B;
	line-height: 23px;
	display: table;
}
.kai_icons{
	width: 145px;
    height: 145px;
    float:left;
    margin:0 15px;
}
.kai_items>.kai_de{
   display: table-cell;
   vertical-align: middle;
   width: 629px;
   height:138px;
}
.kai_items>.kai_de h4{
	color:#0e82cb;
	font-size:20px;
	margin-left:10px;
}
.kai_items>.kai_de span{
	width: 6px;
    height: 6px;
    border: 2px solid #007CC6;
    border-radius: 50%;
    display: inline-block;
    margin-right:5px;
}
.kai_items>.kai_de p{
	font-size:16px;
	margin:10px;
	line-height:25px;
}
.case li{
	position:relative;
	width: 769px;
	height: 221px;
	background: #FFFFFF;
	border: 1px solid #268CF5;
	border-radius: 0px 0px 20px 0px;
	margin:0 auto;
	margin-top:30px;
	display: flex;
	// align-items:center;
}
.case li .tis{
   width: 140px;
	height: 35px;
	display: inline-block;
	background: linear-gradient(-75deg, #278BF5 0%, #03A9F7 76%);
	border: 1px solid #268CF5;
	border-radius: 20px 0px 0px 0px;
	text-align:center;
	line-height:35px;
	color:#fff;
	position:absolute;
	top:-19px;
	left:0px;
}
.case li .case_img{
	width:150px;
	height:58px;
	margin-top:90px;
}
.case_items{
	flex:1;
	padding:10px;
}
.case_items h6{
	font-size:20px;
	font-weight: bold;
	color: #268CF5;
	line-height: 42px;
	margin-top:15px;
}
.case_items_list{
	font-size:16px;
	color: #272626;
	line-height: 26px;
	// display: flex;
}
.more_case{
	width: 159px;
	height: 41px;
	font-size:12px;
	line-height: 40px;
	display: block;
	margin:10px auto;
	text-align:center;
	color:#157AE4;
	border: 1px solid #007CC6;
	cursor:pointer;
}
.kai_desc1{
	font-size:19px;
	font-weight: bold;
	color: #007CC6;
	line-height: 42px;
	text-align:center;
}





}


</style>







